<template>
  <div>
    <!-- desktop ----_ -->
    <div class="mb-xl-details-forms ml-3 mr-3 pl-8 pr-8">
      <v-row>
        <v-col
          style="text-align: initial"
          class="mt-14 pl-0 pr-0 mb-5"
          cols="12"
        >
          <h1 class="forms-details__title">{{ title }}</h1>
        </v-col>
      </v-row>
    </div>

    <!-- tablet -->

    <v-row class="mb-t-details px-0 mx-0">
      <v-col cols="12" class="text-left mr-0 ml-0">
        <div class="d-flex mt-6">
          <v-btn @click="$router.go(-1)" x-small class="mt-0 mb-2" fab icon>
            <img width="15" src="../../assets/icon/navigate_before.png" />
          </v-btn>
          <p class="text-title-1 mb-0 pb-0">
            <label
              style="padding-top: 30%"
              class="text-apps-blue-sm txt-capitalize"
            >
              {{ name }},
            </label>
            <label style="padding-top: 30%" class="text-apps-blue-sm">
              está viendo
            </label>
          </p>
        </div>
        <p class="text-apps-sm mb-1 ml-9">
          {{ title }}
        </p>
      </v-col>
    </v-row>

    <!-- mobile -->

    <v-row
      class="mb-sm-details"
      style="padding-left: 50px; text-align: left; display: flex"
    >
      <p class="txt-title-mov">{{ title }}</p>
    </v-row>

    <!-- FORMULARIO -->
    <v-row justify="center" class="mt-0 px-0 mx-0 pb-15 mb-10">
      <!-- <h1 class="text-title text-left desktop-hidden" style="padding-bottom:21px;">{{ title }}</h1> -->

      <v-col cols="10" md="10" xs="10" sm="10" lg="6" xl="9">
        <v-form ref="form">
          <template v-for="(data, i) in show1">
            <!-- TEXTO -->
            <template v-if="data.typeForm == typeForm.INPUT_TEXT">
              <template v-if="!data.show">
                <p :key="'txt-input' + i" class="text-f">
                  {{ data.question_label }}
                </p>
                <v-text-field
                  @change="validateCon(data, i)"
                  style="margin-bottom: 80px"
                  :rules="data.rules"
                  v-model="data.value"
                  :key="'input' + i"
                  type="text"
                  placeholder="Ingrese aquí su respuesta"
                  class="mt-1 mb-3"
                  :append-icon="data.rules.length > 0 ? 'mdi-asterisk' : ''"
                  :disabled="data.class === 'disabled' ? true : false"
                ></v-text-field>
              </template>
            </template>

            <!-- HTML -->
            <template v-if="data.typeForm == typeForm.HTML">
              <template v-if="!data.show">
                <div
                  style="text-align: initial"
                  class="mb-5 mt-5"
                  :key="'html' + i"
                  v-html="data.codigo_html"
                ></div>
              </template>
            </template>

            <!-- SECTION BREAK -->

            <template v-if="data.typeForm == typeForm.SECTION_BREAK">
              <template v-if="!data.show">
                <h2 style="text-align: initial" :key="'h2_section' + i">
                  {{ data.question_label }}
                </h2>
                <div
                  style="text-align: initial"
                  class="mb-5"
                  :key="'section' + i"
                  v-html="data.description"
                ></div>
                <v-divider class="mb-5 mt-5" :key="'j' + i"></v-divider>
              </template>
            </template>

            <!-- middle name -->

            <!-- <template v-if="data.name == typeForm.INPUT_TEXT1">
              <p :key="'j' + i" class="text-f">{{ data.question_label }}</p>
              <v-text-field
                :rules="data.rules"
                v-model="data.value"
                :key="'j' + i"
                type="text"
                :placeholder="data.question_label"
                class="mt-1 mb-3"
                :append-icon="
                  data.validation_rules.required.value == true
                    ? 'mdi-asterisk'
                    : ''
                "
              ></v-text-field>
            </template> -->

            <!-- last name -->

            <!-- <template v-if="data.name == typeForm.INPUT_TEXT2">
              <p :key="'j' + i" class="text-f">{{ data.question_label }}</p>
              <v-text-field
                :rules="data.rules"
                v-model="data.value"
                :key="'j' + i"
                type="text"
                :placeholder="data.question_label"
                class="mt-1 mb-3"
                :append-icon="
                  data.validation_rules.required.value == true
                    ? 'mdi-asterisk'
                    : ''
                "
              ></v-text-field>
            </template> -->

            <!--Campo de texto -->

            <!-- <template v-if="data.name == typeForm.INPUT_TEXT3">
              <p :key="'j' + i" class="text-f">{{ data.question_label }}</p>
              <v-text-field
                :rules="data.rules"
                v-model="data.value"
                :key="'j' + i"
                type="text"
                :placeholder="data.question_label"
                class="mt-1 mb-3"
                :append-icon="
                  data.validation_rules.required.value == true
                    ? 'mdi-asterisk'
                    : ''
                "
              ></v-text-field>
            </template> -->

            <!--input_mask -->

            <!-- <template v-if="data.name == typeForm.INPUT_TEXT4">
              <p :key="'j' + i" class="text-f">{{ data.question_label }}</p>
              <v-text-field
                :rules="data.rules"
                v-model="data.value"
                :key="'j' + i"
                type="text"
                :placeholder="data.question_label"
                class="mt-1 mb-3"
                :append-icon="
                  data.validation_rules.required.value == true
                    ? 'mdi-asterisk'
                    : ''
                "
              ></v-text-field>
            </template> -->

            <!--URL -->

            <template v-if="data.typeForm == typeForm.URL">
              <template v-if="!data.show">
                <p :key="'txt-url' + i" class="text-f">
                  {{ data.question_label }}
                </p>
                <v-text-field
                  @change="validateCon(data, i)"
                  :rules="data.rules"
                  v-model="data.value"
                  :key="'input-1' + i"
                  type="url"
                  :placeholder="data.question_label"
                  class="mt-1 mb-3"
                  :append-icon="data.rules.length > 0 ? 'mdi-asterisk' : ''"
                ></v-text-field>
              </template>
            </template>

            <!-- TEXT -->

            <template v-if="data.typeForm == typeForm.TEXTAREA">
              <template v-if="!data.show">
                <p :key="'txt-textarea' + i" class="text-f">
                  {{ data.question_label }}
                </p>
                <v-textarea
                  @change="validateCon(data, i)"
                  :rules="data.rules"
                  v-model="data.value"
                  :placeholder="data.question_label"
                  :key="'textarea' + i"
                  class="mt-1 mb-3"
                  :append-icon="data.rules.length > 0 ? 'mdi-asterisk' : ''"
                >
                </v-textarea>
              </template>
            </template>

            <!--  NUMBER -->

            <template v-if="data.typeForm == typeForm.INPUT_NUMBER">
              <template v-if="!data.show">
                <p :key="'txt-input-1' + i" class="text-f">
                  {{ data.question_label }}
                </p>
                <v-text-field
                  @change="validateCon(data, i)"
                  :rules="data.rules"
                  v-model="data.value"
                  :key="'input-3' + i"
                  type="number"
                  :placeholder="data.question_label"
                  class="mt-1 mb-3"
                  :append-icon="data.rules.length > 0 ? 'mdi-asterisk' : ''"
                ></v-text-field>
              </template>
            </template>

            <!-- EMAIL -->

            <template v-if="data.typeForm == typeForm.INPUT_MAIL">
              <template v-if="!data.show">
                <p :key="'txt-mail' + i" class="text-f">
                  {{ data.question_label }}
                </p>
                <v-text-field
                  @change="validateCon(data, i)"
                  :rules="data.rules"
                  v-model="data.value"
                  :key="'j' + i"
                  type="input-5"
                  :placeholder="data.question_label"
                  class="mt-1 mb-3"
                  :append-icon="data.rules.length > 0 ? 'mdi-asterisk' : ''"
                ></v-text-field>
              </template>
            </template>

            <!-- PASSWORD -->

            <template v-if="data.typeForm == typeForm.INPUT_PASSWORD">
              <template v-if="!data.show">
                <p :key="'txt-pass' + i" class="text-f">
                  {{ data.question_label }}
                </p>
                <v-text-field
                  @change="validateCon(data, i)"
                  :rules="data.rules"
                  v-model="data.value"
                  :key="'input-6' + i"
                  type="password"
                  :placeholder="data.question_label"
                  class="mt-1 mb-3"
                  :append-icon="data.rules.length > 0 ? 'mdi-asterisk' : ''"
                ></v-text-field>
              </template>
            </template>

            <!-- DATE -->

            <template v-if="data.typeForm == typeForm.INPUT_DATE">
              <template v-if="!data.show">
                <p :key="'txt-datetime' + i" class="text-f">
                  {{ data.question_label }}
                </p>
                <v-menu
                  :key="'datetime' + i"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      @change="validateCon(data, i)"
                      :rules="data.rules"
                      v-model="data.value"
                      :placeholder="data.question_label"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :append-icon="data.rules.length > 0 ? 'mdi-asterisk' : ''"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="data.value"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </template>
            </template>

            <!-- SELECT -->

            <template v-if="data.typeForm == typeForm.SELECT">
              <template v-if="!data.show">
                <p :key="'txt-select' + i" class="text-f">
                  {{ data.question_label }}
                </p>
                <v-autocomplete
                  @change="validateCon(data, i)"
                  :rules="data.rules"
                  v-model="data.value"
                  :key="'input-7' + i"
                  :items="data.options"
                  item-value="value"
                  item-text="label"
                  placeholder="Seleccione"
                  class="mt-3 mb-3"
                  :append-icon="data.rules.length > 0 ? 'mdi-asterisk' : ''"
                  :menu-props="{ contentClass: 'multi-line-menu' }"
                ></v-autocomplete>
              </template>
            </template>

            <!-- MULTI SELECT -->

            <template v-if="data.typeForm == typeForm.MULTI_SELECT">
              <template v-if="!data.show">
                <p :key="'txt-multi' + i" class="text-f">
                  {{ data.question_label }}
                </p>
                <v-autocomplete
                  @change="validateCon(data, i)"
                  multiple
                  :rules="[
                    (v) =>
                      !!data.value.length || 'Seleccione al menos una opción',
                  ]"
                  v-model="data.value"
                  :key="'input-8' + i"
                  :items="data.options"
                  item-value="value"
                  item-text="label"
                  placeholder="Seleccione una o varias opciones"
                  class="mt-3 mb-3"
                  :append-icon="data.rules.length > 0 ? 'mdi-asterisk' : ''"
                ></v-autocomplete>
              </template>
            </template>

            <!-- CHECKBOX -->

            <template v-if="data.typeForm == typeForm.CHECKBOX">
              <template v-if="!data.show">
                <p :key="'txt-check' + i" class="text-f">
                  {{ data.question_label }}
                  <v-icon v-if="data.validation_rules.required.value == true">
                    mdi-asterisk
                  </v-icon>
                </p>
                <template v-for="(check, j) in data.options">
                  <v-checkbox
                    @change="validateCon(data, i)"
                    :rules="[
                      (v) =>
                        !!data.value.length || 'Seleccione al menos una opción',
                    ]"
                    v-model="data.value"
                    :label="check.label"
                    :value="check.value"
                    :key="'check' + j"
                    class="mt-1 mb-1"
                  ></v-checkbox>
                </template>
              </template>
            </template>

            <!-- CHECKBOX TERM-->

            <template v-if="data.typeForm == typeForm.CHECK_TERM">
              <template v-if="!data.show">
                <p :key="'txt-check-term' + i" class="text-f">
                  {{ data.question_label }}
                  <v-icon v-if="data.validation_rules.required.value == true">
                    mdi-asterisk
                  </v-icon>
                </p>
                <v-checkbox
                  @change="validateCon(data, i)"
                  :rules="data.rules"
                  v-model="data.value"
                  :key="'check-1' + i"
                  class="mt-1 mb-1"
                >
                  <template v-slot:label>
                    <div v-html="data.codigo_html"></div>
                  </template>
                </v-checkbox>
              </template>
            </template>

            <!-- CHECKBOX GDPR-->

            <template v-if="data.typeForm == typeForm.CHECK_GDPR">
              <template v-if="!data.show">
                <p :key="'txt-check-gdpr' + i" class="text-f">
                  {{ data.question_label }}
                  <v-icon v-if="data.validation_rules.required.value == true">
                    mdi-asterisk
                  </v-icon>
                </p>
                <v-checkbox
                  @change="validateCon(data, i)"
                  :rules="data.rules"
                  v-model="data.value"
                  :key="'check-2' + i"
                  class="mt-1 mb-1"
                  :label="data.description"
                >
                </v-checkbox>
              </template>
            </template>

            <!-- RADIO -->

            <template
              v-if="
                data.typeForm == typeForm.RADIO &&
                data.container_class != 'container_wrap'
              "
            >
              <template v-if="!data.show">
                <p v-if="!data.show" :key="'txt-radio' + i" class="text-f">
                  {{ data.question_label }}
                </p>
                <v-radio-group
                  v-if="!data.show"
                  @change="validateCon(data, i)"
                  :key="'input-9' + i"
                  :rules="data.rules"
                  v-model="data.value"
                  :append-icon="
                    data.validation_rules.required.value == true
                      ? 'mdi-asterisk'
                      : ''
                  "
                >
                  <v-radio
                    v-for="n in data.options"
                    :key="n"
                    :label="n.label"
                    :value="n.value"
                  ></v-radio>
                </v-radio-group>
              </template>
            </template>

            <template
              v-if="
                data.typeForm == typeForm.RADIO &&
                data.container_class == 'container_wrap'
              "
              class="container_wrap"
            >
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel
                  v-for="(panelData, index) in separateDataByDivider(
                    data.options
                  )"
                  :key="index"
                >
                  <v-expansion-panel-header
                    >{{ panelData[0].label }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-radio-group
                      v-if="!data.show"
                      @change="validateCon(data, i)"
                      :key="'input-9' + i"
                      :rules="data.rules"
                      v-model="data.value"
                      row
                      class="imagen-form"
                      :append-icon="
                        data.validation_rules.required.value == true
                          ? 'mdi-asterisk'
                          : ''
                      "
                    >
                      <template v-for="(n, index) in panelData">
                        <template v-if="n.value !== 'divider'">
                          <v-radio
                            :key="index"
                            :label="n.label"
                            :value="n.value"
                          >
                            <template v-slot:label v-if="n.image">
                              <v-card width="150" color="white">
                                <v-img height="200" :src="n.image"></v-img>
                                <span
                                  :style="{
                                    display: 'block',
                                    textAlign: 'center',
                                  }"
                                  class="mt-3 mb-3"
                                >
                                  {{ n.label }}</span
                                >
                              </v-card>
                            </template>
                          </v-radio>
                        </template>
                      </template>
                    </v-radio-group>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
            <!-- <template>
              {{ separatedData }}
            </template> -->
            <!-- Up load img -->

            <!-- <template v-if="data.name == typeForm.UPLOAD_IMG">
              <v-file-input
                v-model="data.value"
                prepend-icon="mdi-file-image-outline"
                accept="image/png, image/jpeg, image/bmp"
                :rules="data.rules"
                :key="'j' + i"
                class="mt-1 mb-3"
                :placeholder="data.question_label"
                :append-icon="
                  data.validation_rules.required.value == true
                    ? 'mdi-asterisk'
                    : ''
                "
              ></v-file-input>
            </template> -->

            <!-- up load file -->

            <template v-if="data.typeForm == typeForm.UPLOAD_FILE">
              <template v-if="!data.show">
                <p :key="'txt-country' + i" class="text-f">
                  {{ data.question_label }}
                </p>
                <v-file-input
                  :rules="[
                    (v) => !!v || 'El archivo es requerida.',
                    (value) =>
                      !value ||
                      value.size < 1097152 ||
                      'El archivo debe pesar menos de 1MB',
                  ]"
                  color="#1E294D"
                  label="Cargue el archivo aquí"
                  filled
                  rounded
                  single-line
                  dense
                  v-model="data.value"
                  class="mt-3 mb-0"
                  :key="data.id"
                ></v-file-input>
              </template>
            </template>

            <!-- Address -->

            <!-- <template v-if="data.name == typeForm.ADDRESS">
              <p :key="'j' + i" class="text-f">{{ data.question_label }}</p>
              <v-autocomplete
                :rules="data.rules"
                v-model="data.value"
                :key="'j' + i"
                :items="data.sub_campos"
                item-value="value"
                item-text="label"
                :placeholder="data.question_label"
                class="mt-3 mb-3"
                :append-icon="
                  data.rules.length > 0
                    ? 'mdi-asterisk'
                    : ''
                "   
              ></v-autocomplete>
            </template> -->

            <!-- Country select -->

            <template v-if="data.typeForm == typeForm.SELECT_COUNTRY">
              <template v-if="!data.show">
                <p :key="'txt-country' + i" class="text-f">
                  {{ data.question_label }}
                </p>
                <v-autocomplete
                  @change="validateCon(data, i)"
                  :rules="data.rules"
                  v-model="data.value"
                  :key="'input-10' + i"
                  :items="data.select_country"
                  item-value="value"
                  item-text="label"
                  :placeholder="data.question_label"
                  class="mt-3 mb-3"
                  :append-icon="data.rules.length > 0 ? 'mdi-asterisk' : ''"
                ></v-autocomplete>
              </template>
            </template>

            <!-- IMG -->
            <template v-if="data.typeForm == typeForm.IMG">
              <template v-if="!data.show">
                <p :key="'txt-country' + i" class="text-f">
                  {{ data.question_label }}
                </p>
                <v-file-input
                  :rules="[
                    (v) => !!v || 'La imagen es requerida.',
                    (value) =>
                      !value ||
                      value.size < 1097152 ||
                      'La imagen debe pesar menos de 1MB',
                  ]"
                  accept=".png, .jpg"
                  color="#1E294D"
                  label="Cargue la imagen aquí"
                  filled
                  rounded
                  single-line
                  dense
                  v-model="data.value"
                  class="mt-3 mb-0"
                  :key="data.id"
                ></v-file-input>
              </template>
            </template>

            <!-- BOTONES NO DINAMICO -->

            <!-- <template  v-if="data.typeForm == typeForm.SUBMIT">
          <v-btn
          @click="generateConsumo(data)"
          :key="'input-101' + i"
            v-if="!data.show && data.container_class"
            dark
            class="btn-main ma-2"
          >
            Guardar
          </v-btn>
        </template> -->

            <!-- CUSTOM BUTTON -->

            <template v-if="data.typeForm == typeForm.SUBMIT">
              <v-btn
                :key="'input-10' + i"
                v-if="!data.show && data.container_class"
                dark
                rounded
                class="forms-details__button ma-2"
                @click="buttonActions(data.container_class)"
              >
                {{ data.button_ui.text }}
              </v-btn>
            </template>

            <!-- Dropdown select -->

            <!-- <template v-if="data.name == typeForm.DROPDOWN">
              <p :key="'j' + i" class="text-f">{{ data.question_label }}</p>
              <v-select
                :rules="data.rules"
                v-model="data.value"
                :key="'j' + i"
                :items="data.options"
                item-value="value"
                item-text="label"
                :placeholder="data.question_label"
                class="mt-3 mb-3"
                :append-icon="
                  data.validation_rules.required.value == true
                    ? 'mdi-asterisk'
                    : ''
                "
              ></v-select>
            </template> -->
          </template>
        </v-form>

        <!-- <v-divider></v-divider> -->

        <!-- Boton por default -->
        <v-row class="mt-5" v-if="!customButton">
          <v-col cols="12">
            <v-layout justify-end>
              <v-btn
                v-if="!customButton"
                dark
                rounded
                class="forms-details__button"
                block
                @click="save()"
              >
                guardar
              </v-btn>
            </v-layout>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar
      center
      outlined
      :color="alert.color"
      v-model="alert.show"
      timeout="5000"
    >
      <b style="font-size: 15px">{{ alert.text }}</b>

      <template v-slot:action="{ attrs }">
        <v-btn
          color="black"
          text
          v-bind="attrs"
          fab
          icon
          @click="alert.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-overlay :value="loading.all">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>

    <!-- modal envio de respuestas -->

    <!-- Dialog custom greeting -->
    <v-dialog
      v-model="dialog"
      persistent
      content-class="greeting"
      max-width="460"
    >
      <v-card>
        <v-card-text v-html="dialogMessage" class="pt-3 dialog-text">
        </v-card-text>
        <v-card-actions class="button-greeting">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="$router.go(-1)"
            dark
            rounded
            class="forms-details__button"
          >
            No autorizar
          </v-btn>
          <v-btn
            text
            @click="dialog = false"
            :disabled="disabled"
            dark
            rounded
            class="forms-details__button"
          >
            Autorizar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog save default -->

    <!-- <v-dialog
      v-model="modalSend"
      persistent
      justify="center"
      content-class="saveDialog"
      max-width="460"
    >
      <v-card>
        <v-card-title class="forms-dialog-title">
          <h3>Hemos enviado tu solicitud</h3>
        </v-card-title>
        <v-card-text>
          <p>Respuestas registradas correctamente</p>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- Dialog custom response -->
    <v-dialog
      v-model="response"
      persistent
      justify="center"
      content-class="response"
      max-width="460"
    >
      <v-card>
        <v-card-text
          v-html="responseMessage"
          class="pt-3 dialog-text"
        ></v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog code confirmation -->
    <v-dialog
      v-model="dialogCode"
      justify="center"
      content-class="overflow confirmation"
      max-width="460"
    >
      <v-card>
        <v-card-title class="forms-dialog-title">
          <b>Confirmación del código</b>
        </v-card-title>
        <v-card-text class="left dialog-text">
          Se ha enviado un código de seguridad al email suministrado. Por favor
          ingrese el código aquí para poder continuar
        </v-card-text>
        <v-card-actions>
          <v-row class="code-confirmation">
            <v-col cols="7">
              <div class="ma-auto" style="max-width: 300px">
                <v-otp-input
                  v-model="code"
                  :length="length"
                  plain
                ></v-otp-input>
              </div>
            </v-col>
            <v-col cols="2" class="my-3">
              <v-btn
                block
                :loading="isLoading"
                :disabled="!isActive"
                text
                dark
                rounded
                class="forms-details__button"
                @click="codeConfirmation"
                >Confirmar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog Errores -->
    <!-- <v-dialog v-model="dialogError" content-class="error" max-width="460">
      <v-card>
        <v-card-text class="pt-3">
          <p class="dialogError">
            <span>
              Hubo un error, revisa los campos diligenciados o intenta mas
              tarde...!
            </span>
            <br />
            <br />
            <span class="errorType"> <b>Error:</b> {{ errorMessage }} </span>
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialogError = false"
            dark
            rounded
            class="btn-main"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->

    <Alert
      :open="alertM.open"
      :text="alertM.txt"
      :title="alertM.title"
      :type="alertM.type"
      @close="closeM"
      :redirect="alertM.redirect"
    >
    </Alert>

    <!-- fin  modal envio de respuestas -->
  </div>
</template>
<script>
import { TYPEFORM } from "@/utils/util.js";
import Api from "@/utils/api";
import axios from "axios";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  props: ["id"],
  data() {
    return {
      panel: [0],
      alertM: {
        txt: "",
        title: "",
        type: "",
        open: false,
        redirect: "",
      },
      customButton: false,
      id_profile: null,
      modalSend: false,
      formId: null,
      document: null,
      title: null,
      alert: {
        show: false,
        text: null,
        color: null,
      },
      typeForm: TYPEFORM,
      show: [],
      show1: [],
      info: {},
      menu: false,
      forms: {
        form_id: "",
        ip: "192.168.1.1",
        answers: [],
      },
      loading: {
        all: false,
      },
      name: "",
      dialog: false,
      dialogMessage: null,
      dialogCode: false,
      dialogError: false,
      errorMessage: "",
      disabled: true,
      response: false,
      responseMessage: null,
      code: "",
      length: 4,
      infoToken: {
        document: "",
      },
      name: "",
      token: "",
      isLoading: false,
      fileFlag: null,
      getFormsAnswerFlag: false,
      getFormsNoResponseFlag: false,
      document_sing_id: null,
      idToRoute: null,
      separatedData: [],
    };
  },
  methods: {
    closeM() {
      console.log("cerrar");
      this.alertM.open = false;
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },

    getTecInfo() {
      Api.noAuth()
        .getTecInfo(this.infoToken.document)
        .then((res) => res.json())
        .then(async (data) => {
          this.infoTec = data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.forms.answers = [];
        const formData = new FormData();
        var r = {};
        this.show1.map((elem) => {
          if (elem.value) {
            var key = elem.name;
            r[key] = elem.value;
          }
          if (elem.typeForm === "file" || elem.typeForm === "file-document") {
            this.fileFlag = true;
            formData.append(elem.name, elem.value);
            r[key] = "";
          }
        });
        this.loading.all = true;
        if (await this.fileFlag) {
          let title = this.removeAccents(this.title).toLowerCase();
          r.cedula = this.document;
          r.id_login = this.id_profile;
          var dataS = {
            answer: [r],
          };
          formData.append("answers", JSON.stringify(dataS));
          formData.append("form_id", this.formId);
          formData.append("ip", "192.168.1.1");
          for (const pair of formData.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`);
          }
          console.log(formData)
          Api.noAuth()
          .sendForms(formData, "file")
            .then(async (data) => {
              this.loading.all = false;
              if (data.status == 200) {
                if (this.formId == 39) {
                  flag = false;
                  this.saveSalvo(this.forms);
                }
                setTimeout(() => {
                  this.response = false;
                }, 4000);
                this.alertM.open = true;
                this.alertM.title = "Excelente";
                this.alertM.txt = "Hemos enviado las respuestas";
                this.alertM.type = "success";
                this.alertM.redirect = "forms";
                this.$emit("close");
                this.getFormsAnswer(this.document);
                this.getFormsNoResponse(this.document, this.id_profile);
                if (
                  (await this.getFormsAnswerFlag) &&
                  (await this.getFormsNoResponseFlag) &&
                  flag
                ) {
                  setTimeout(() => {
                    this.$router.push({ name: "forms" });
                  }, 6000);
                }
              } else {
                this.alertM.open = true;
                this.alertM.title = "¡Oh no!";
                this.alertM.txt = "Inténtalo más tarde";
                this.alertM.type = "error";
                this.alertM.redirect = "";
              }
            })
            .catch((error) => {
              this.loading.all = false;
              this.alertM.open = true;
              this.alertM.title = "¡Oh no!";
              this.alertM.txt = "Inténtalo más tarde";
              this.alertM.type = "error";
              this.alertM.redirect = "";
            });

          return false;
        } else {
          r.cedula = this.document;
          r.id_login = this.id_profile;
          this.forms.form_id = this.formId;
          this.forms.answers.push(r);
          let flag = true;
          Api.noAuth()
            .sendForms(this.forms, "json")
            .then((resp) => {
              console.log(resp);
              return resp.json();
            })
            .then(async (data) => {
              console.log(data);
              this.loading.all = false;
              if (data.cod == 0) {
                if (this.formId == 39) {
                  flag = false;
                  this.saveSalvo(this.forms);
                }
                setTimeout(() => {
                  this.response = false;
                }, 4000);
                // setTimeout(() => {
                this.modalSend = true;
                this.alertM.open = true;
                this.alertM.title = "Excelente";
                this.alertM.txt = "Hemos enviado las respuestas";
                this.alertM.type = "success";
                this.$emit("close");

                this.getFormsAnswer(this.document);
                this.getFormsNoResponse(this.document, this.id_profile);
                if (this.document_sing_id == undefined) {
                  setTimeout(() => {
                    this.$router.push({ name: "forms" });
                  }, 3000);
                } else {
                  this.$router.push({
                    name: "documentDetails",
                    params: {
                      id: this.document_sing_id,
                      docUser: this.idToRoute,
                    },
                  });
                }

                // }, 5000);
                if (
                  (await this.getFormsAnswerFlag) &&
                  (await this.getFormsNoResponseFlag) &&
                  flag
                ) {
                  // setTimeout(() => {
                  //   this.$router.push({ name: "forms" });
                  // }, 6000);
                }
              } else {
                this.alertM.open = true;
                this.alertM.title = "¡Oh no!";
                this.alertM.txt = "Inténtalo más tarde";
                this.alertM.type = "error";
                this.alertM.redirect = "";
                // this.dialogError = true;
              }
            })
            .catch((error) => {
              this.loading.all = false;
              console.log(error);
            });
        }
      } else {
        this.loading.all = false;
      }
    },

    saveSalvo(forms) {
      Api.noAuth()
        .saveSafePassage(forms.answers[0])
        .then((res) => {
          if (res.data.cod == 0) {
            var result = res.data.data.status;
            var format = "";
            if (!result) {
              format = "success";
            } else {
              format = "error";
            }
            this.$router.push({
              name: "formsSagePassage",
              params: { result: format },
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    validateCon(data, i) {
      for (let i = 0; i < this.show1.length; i++) {
        if (
          this.show1[i].conditional_logics != undefined &&
          this.show1[i].conditional_logics.status == true
        ) {
          var conditions = this.show1[i].conditional_logics.conditions;
          if (conditions[0].field == data.name) {
            // console.log(conditions[0]);
            switch (conditions[0].operator) {
              case "=":
                if (conditions[0].value == data.value) {
                  // igual
                  this.show1[i].show = false;
                } else {
                  this.show1[i].show = true;
                }
                break;
              case "!=":
                if (conditions[0].value == data.value) {
                  // diferente igual
                  this.show1[i].show = true;
                } else {
                  this.show1[i].show = false;
                }
                break;
              case ">":
                if (parseInt(conditions[0].value) > parseInt(data.value)) {
                  // mayor
                  this.show1[i].show = false;
                } else {
                  this.show1[i].show = true;
                }
                break;
              case "<":
                if (parseInt(conditions[0].value) < parseInt(data.value)) {
                  // menor
                  this.show1[i].show = false;
                } else {
                  this.show1[i].show = true;
                }
                break;
              case ">=":
                if (parseInt(conditions[0].value) >= parseInt(data.value)) {
                  // mayor igual
                  this.show1[i].show = false;
                } else {
                  this.show1[i].show = true;
                }
                break;
              case "contains":
                if (conditions[0].value.includes(data.value)) {
                  // inclusión
                  this.show1[i].show = false;
                } else {
                  this.show1[i].show = true;
                }
                break;
              case "doNotContains":
                if (!conditions[0].value.includes(data.value)) {
                  // no inclusión
                  this.show1[i].show = false;
                } else {
                  this.show1[i].show = true;
                }
                break;
              case "startsWith":
                var text = data.value;
                var textFirst = text.split(" ")[0];
                if (!conditions[0].value == textFirst) {
                  // Primera palabra
                  this.show1[i].show = false;
                } else {
                  this.show1[i].show = true;
                }
                break;
              case "endWith":
                // última palabra
                var text = data.value;
                var textFirst = text.split(" ")[0];
                if (!conditions[0].value == textFirst) {
                  this.show1[i].show = false;
                } else {
                  this.show1[i].show = true;
                }
                break;
              case "test_regex":
                // espresión regular
                let re = new RegExp(conditions[0].value);
                if (re.test(data.value)) {
                  this.show1[i].show = false;
                } else {
                  this.show1[i].show = true;
                }
                break;
              default:
                break;
            }
          }
        }
      }
    },

    getValidatecondicional(name, value) {
      for (let i = 0; i < this.show1.length; i++) {
        if (
          this.show1[i].conditional_logics != undefined &&
          this.show1[i].conditional_logics.status == true
        ) {
          var conditions = this.show1[i].conditional_logics.conditions;
          if (conditions[0].value == value) {
            // console.log("H");
            // console.log(this.show1[i]);
            this.show1[i].show = true;
          } else {
            this.show1[i].show = false;
          }
        }
      }
      // console.log(this.show1);
    },

    getForms() {
      this.loading.all = true;
      Api.noAuth()
        .getFormsQuestions(this.formId)
        .then((resp) => resp.json())
        .then((data) => {
          this.show = data.data[0].questions;
          this.show1 = data.data[0].questions;
          this.title = data.data[0].title;
          this.formId = data.data[0].id;
          this.loading.all = false;
          for (let i = 0; i < this.show1.length; i++) {
            // VALIDANDO ESTRCUTURA DEL FORMULARIO
            if (this.show1[i].type == "text") {
              if (this.show1[i].name.includes("datetime")) {
                this.show1[i].typeForm = "datetime";
              } else {
                this.show1[i].typeForm = this.show1[i].type;
              }
            } else if (this.show1[i].type == "select") {
              if (this.show1[i].name.includes("multi_select")) {
                this.show1[i].typeForm = "multi_select";
              } else {
                this.show1[i].typeForm = this.show1[i].type;
              }
            } else if (this.show1[i].type == "file") {
              if (this.show1[i].name.includes("file-upload")) {
                this.show1[i].typeForm = "file-document";
              } else {
                this.show1[i].typeForm = this.show1[i].type;
              }
            } else if (this.show1[i].type == "checkbox") {
              if (this.show1[i].name.includes("gdpr-agreement")) {
                this.show1[i].typeForm = "gdpr-agreement";
              } else if (this.show1[i].name.includes("terms_n_condition")) {
                this.show1[i].typeForm = "terms_n_condition";
              } else {
                this.show1[i].typeForm = this.show1[i].type;
                this.show1[i].value = [];
              }
            } else {
              this.show1[i].typeForm = this.show1[i].type;
            }

            // VALIDANDO SI TIENE O NO CONDICIONES

            if (this.show1[i].hasOwnProperty("validation_rules")) {
              if (this.show1[i].validation_rules.required.value) {
                this.show1[i].rules = [
                  (v) => !!v || this.show1[i].validation_rules.required.message,
                ];
              } else {
                this.show1[i].rules = [];
              }
            } else {
              this.show1[i].rules = [];
            }

            // VALIDAR EL HIDDEN
            if (this.show1[i].hasOwnProperty("conditional_logics")) {
              this.show1[i].show = this.show1[i].conditional_logics.status;
            } else {
              this.show1[i].show = false;
            }

            // VALIDAR HIIDEN 2 NO FUNCIONA

            // if (this.show1[i].container_class == "hidden") {
            //   this.show1[i].show = true;
            // } else {
            //   this.show1[i].show = false;
            // }

            // VALIDAR SI TIENE BOTONES PERSONALIZADOS
            if (
              this.show1[i].type == "submit" &&
              this.show1[i].name !== "button"
            ) {
              this.customButton = true;
              this.show1[i].show = false;
            }

            // DIALOG PERSONALIZADO PARA ENTRADA AL FORMULARIO
            if (
              this.show1[i].container_class === "greeting_v1" ||
              this.show1[i].container_class === "greeting_v2"
            ) {
              this.show1[i].show = true;
              this.dialog = true;
            }

            // RESPUESTA DEL FORMULARIO -> INICIA OCULTA SIEMPRE
            if (this.show1[i].container_class === "response") {
              this.show1[i].show = true;
              this.responseMessage = this.show1[i].codigo_html;
            }

            // SE OCULTA TODOS LOS CAMPOS DESHABILITADOS CUANDO NO SE CONSUME INFORMACION
            if (this.show1[i].class === "disabled") {
              this.show1[i].show = true;
            }

            // TRAER DATA PERSONALIZADA
            if (this.show1[i].name === "get_data") {
              this.getData(this.show1[i].value);
            }
          }
          // console.log(this.show1);
        })
        .catch((error) => {
          this.loading.all = false;
          console.log(error);
        })
        .finally(() => {
          for (let i = 0; i < this.show1.length; i++) {
            if (this.show1[i].type == "radio") {
              // this.separateDataByDivider(this.show1[i].options);
            }
          }
        });
    },

    getData(endpoint) {
      this.loading.all = true;
      axios
        .get(`${endpoint}`, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.loading.all = false;
          if (res.data.data) {
            let userData = res.data.data;
            this.show1.forEach((show) => {
              Object.keys(userData).forEach((property) => {
                if (show.container_class === property) {
                  show.value = userData[`${property}`];
                  show.show = false;
                }
              });
              // Validación para saludo #1 (Cuando tenemos informacion en la BD)
              if (
                show.container_class &&
                show.container_class === "greeting_v1"
              ) {
                this.dialogMessage = show.codigo_html;
              }
              // Ocultar botones exclusivos saludo #2
              if (show.class === "greeting_v2") {
                show.show = true;
              }
            });
            this.disabled = false;
          } else {
            // Validación para saludo #2 (Cuando no tenemos informacion en la BD)
            this.show1.forEach((show) => {
              if (
                show.container_class &&
                show.container_class === "greeting_v2"
              ) {
                this.dialogMessage = show.codigo_html;
              }
              // Ocultar botones exclusivos saludo #1
              if (show.class === "greeting_v1") {
                show.show = true;
              }

              if (show.container_class === "document_number") {
                // Validacion exclusiva para dotación
                show.show = false;
                show.value = this.document;
              }
            });
            this.disabled = false;
          }
        })
        .catch((err) => {
          this.loading.all = false;
          // this.dialogError = true;
          this.alertM.open = true;
          this.alertM.title = "¡Oh no!";
          this.alertM.txt = err.response.data.message;
          this.alertM.type = "error";
          this.alertM.redirect = "";
          console.log(err);
          // this.errorMessage = err.response.data.message;
        });
    },

    buttonActions(endpoint) {
      this.loading.all = true;
      if (endpoint != "null") {
        let data = {};
        this.show1.forEach((show) => {
          if (show.container_class && show.value) {
            data[show.container_class] = show.value;
          }
        });
        axios
          .post(`${endpoint}`, data, {
            headers: {
              Authorization: "Bearer " + this.token,
            },
          })
          .then((res) => {
            console.log(res);
            this.document_sing_id = res.data.data.document_sing_id;
            this.idToRoute = res.data.data.id;
            this.codeConfirmation();
            // this.dialogCode = true;
            // this.loading.all = false;
          })
          .catch((err) => {
            console.log(err);
            // this.errorMessage = err.response.data.message;
            // this.dialogError = true;

            this.loading.all = false;
            this.alertM.open = true;
            this.alertM.title = "¡Oh no!";
            this.alertM.txt = err.response.data.message;
            this.alertM.type = "error";
            this.alertM.redirect = "";
          });
      }
      if (endpoint == "null") {
        try {
          this.save();
        } catch (error) {
          this.loading.all = false;
          console.log(err);
          // this.errorMessage = err.response.data.message;
          // this.dialogError = true;
          this.alertM.open = true;
          this.alertM.title = "¡Oh no!";
          this.alertM.txt = err.response.data.message;
          this.alertM.type = "error";
          this.alertM.redirect = "";
        }
      }
    },

    codeConfirmation() {
      // this.loading.all = true;
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.response = false; // respuesta
        this.dialogCode = false;
        this.save();
      }, 1000);

      // this.show1.forEach((show) => {
      //   console.log(show)
      //   if (show.name === "code_confirmation") {
      //     let data = {
      //       code: this.code,
      //     };
      //     axios
      //       .post(`${show.value}`, data, {
      //         headers: {
      //           Authorization: "Bearer " + this.token,
      //         },
      //       })
      //       .then((res) => {
      //         // this.loading.all = false;
      //         this.isLoading = false;
      //         this.response = true;
      //         this.dialogCode = false;
      //         this.save();
      //       })
      //       .catch((err) => {
      //         console.log(err);
      //         // this.loading.all = false;
      //         this.isLoading = false;
      //         // this.errorMessage = err.response.data.message;
      //         // this.dialogError = true;

      //         this.alertM.open = true;
      //         this.alertM.title = "¡Oh no!";
      //         this.alertM.txt = err.response.data.message;
      //         this.alertM.type = "error";
      //         this.alertM.redirect = "";
      //       });
      //   }
      // });
    },

    removeAccents(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },

    getFormsAnswer(document) {
      Api.noAuth()
        .getFormsAnswer(document)
        .then((resp) => resp.json())
        .then((data) => {
          let array = data.data;
          let removeDuplicate = (arr) => {
            let appeared = {};
            for (let i = 0; i < arr.length; ) {
              if (!appeared.hasOwnProperty(arr[i].title)) {
                appeared[arr[i].title] = 1;
                i++;
                continue;
              }
              arr.splice(i, 1);
            }
            return arr;
          };
          let formFilter = removeDuplicate(array);
          // this.$store.dispatch("updateFormsAnswer", formFilter);
          this.getFormsAnswerFlag = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getFormsNoResponse(document, profile) {
      Api.noAuth()
        .getFormsNoResponse(document, profile)
        .then((resp) => resp.json())
        .then((data) => {
          this.getFormsNoResponseFlag = true;
          this.$store.dispatch("updateFormsNoResponse", data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    separateDataByDivider(data) {
      let separatedData = [];
      let currentArray = [];

      for (let i = 0; i < data.length; i++) {
        if (data[i].value === "divider") {
          if (currentArray.length > 0) {
            separatedData.push(currentArray.slice());
            currentArray = [];
          }
          // Incluimos el elemento divider en el array separado
          currentArray.push(data[i]);
        } else {
          currentArray.push(data[i]);
        }
      }

      if (currentArray.length > 0) {
        separatedData.push(currentArray.slice());
      }

      return separatedData;
    },
  },
  created() {
    this.getToken();
    this.id_profile = sessionStorage.getItem("id_profile");
  },
  watch: {
    id: function (n) {
      if (n) {
        this.formId = n;
        this.getForms();
      }
    },
  },
  computed: {
    isActive() {
      return this.code.length === this.length;
    },
  },

  mounted() {
    console.log(this.id);
    this.formId = this.$route.params.id;
    if (this.id != undefined) {
      this.formId = this.id;
    }

    this.document = sessionStorage.getItem("document_number");
    this.getForms();
    this.getTecInfo();
  },
};
</script>
<style>
@import "./../../assets/css/main.less";

.mdi-asterisk::before {
  content: "\F06C4";
  font-size: 15px;
}
.multi-line-menu .v-list-item__title {
  white-space: normal !important; /* Forzar el salto de línea */
}
.text-f {
  color: #353535;
  font-weight: bold;
  text-align: initial;
  font-size: 18px;
  margin-bottom: 0px !important;
}
.v-expansion-panel-content__wrap
  .v-input--radio-group--row
  .v-input--radio-group__input {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px 10px;
}
.mb-xl-details-forms {
  display: block;
}

.mb-t-details {
  display: none !important;
}

.mb-sm-details {
  display: none !important;
}

.mb-sm-detail-form {
  display: none;
}

.desktop-hidden {
  display: none;
}

.txt-hola-title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}

.left {
  text-align: left;
}

.dialogError {
  padding-top: 35px !important;
  font-size: 20px;
  color: #466be3;
  font-family: "RobotoMedium";
  font-weight: 700;
  text-align: center;
  word-break: break-word !important;
}

.dialog-text {
  font-family: "RobotoRegular";
  font-size: 14px;
  font-weight: 400;
  color: #626262 !important;
  word-break: break-word !important;
}

.overflow {
  overflow-y: inherit !important;
}

.forms-dialog-title {
  font-size: 20px;
  color: #466be3 !important;
  font-family: "RobotoMedium";
  font-weight: 700;
  text-align: center;
  word-break: break-word !important;
}

.errorType {
  color: #000000 !important;
}

.forms-details__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.forms-details__button {
  color: #fff;
  background-color: #466be3 !important;
}

.forms-details__button:hover {
  background-color: #324ca1 !important;
}

/* LAPTOP 4k */
@media (max-width: 2000px) {
  .error {
    width: 35% !important;
  }

  .confirmation {
    width: 35% !important;
  }

  .greeting {
    width: 35% !important;
  }

  .response {
    width: 35% !important;
  }

  .saveDialog {
    width: 35% !important;
  }
}

/* LAPTOP LARGE */
@media (max-width: 1600px) {
}

/* LAPTOP LARGE */
@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */
@media (max-width: 1200px) {
}

/* TABLET */
@media (max-width: 1023px) {
  .mb-xl-details-forms {
    display: none;
  }

  .mb-t-details {
    display: flex !important;
  }

  .mb-sm-details {
    display: none !important;
  }

  .desktop-hidden {
    display: none;
  }

  .mb-sm-detail-form {
    padding-bottom: 80px;
    display: flex;
  }

  .txt-title-mov {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;
    color: #626262;
  }

  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
    font-family: "RobotoRegular";
  }

  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "RobotoRegular";
  }

  .code-confirmation {
    flex-direction: column;
  }

  .button-greeting {
    flex-direction: column;
  }

  .button-greeting button {
    margin: 5px;
  }

  .error {
    width: 60% !important;
  }

  .confirmation {
    width: 50% !important;
  }

  .greeting {
    width: 50% !important;
  }

  .response {
    width: 40% !important;
  }

  .saveDialog {
    width: 40% !important;
  }
}

/* MOBILE L - 767px*/
@media (max-width: 600px) {
  .mb-xl-details-forms {
    display: none;
  }

  .mb-t-details {
    display: none;
  }

  .mb-sm-details {
    display: flex;
  }

  .text-title-xl {
    color: #466be3 !important;
  }

  .desktop-hidden {
    display: block;
  }

  .txt-title-mov {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 25px;

    /* Complementarios/gris 4 */

    color: #626262;
  }

  .text-apps-blue-sm {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
    font-family: "RobotoRegular";
  }

  .text-apps-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "RobotoRegular";
  }

  .confirmation {
    width: 80% !important;
  }

  .greeting {
    width: 80% !important;
  }

  .response {
    width: 60% !important;
  }

  .saveDialog {
    width: 80% !important;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
}

/* MOBILE S */
@media (max-width: 320px) {
}
</style>
